import { useState } from "react";
import {
  AppLayout,
  Header,
  Container,
  FormField,
  Input,
  Button,
  SpaceBetween
 } from "@cloudscape-design/components";

import "@cloudscape-design/global-styles/index.css"

export default function App() {
  const [value, setValue] = useState("");

  return (
    <AppLayout 
      toolsHide='true'
      navigationHide='true'
      splitPanelOpen='true'
      contentHeader={
        <Header
          variant="h1"
          description="Here to help you achieve the best version of yourself!"
          actions={
            <Button variant="primary">Button</Button>
          }
        >
          Body Shop
        </Header>
      }
      content={
        <Container>
          <SpaceBetween size="m">
            <FormField label="Start editing to see some magic happen">
              <Input
                value={value}
                onChange={(event) => setValue(event.detail.value)}
              />
            </FormField>
            <Button variant="primary">Click me</Button>
          </SpaceBetween>
        </Container>
      } />
  );
}